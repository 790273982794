
import Vue from "vue";
import App from "./App";


import BlackDashboard from "./plugins/blackDashboard";
import i18n from "./i18n"
//  import './registerServiceWorker'
 import VueMeta from 'vue-meta'
//  import IsDemo from './plugins/isDemo'
 
 Vue.use(BlackDashboard);
 Vue.use(VueMeta, { keyName: 'head' })


import { library } from '@fortawesome/fontawesome-svg-core'
import { faSmog } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faSmog)

Vue.component('font-awesome-icon', FontAwesomeIcon)

 /* eslint-disable no-new */
 new Vue({
   i18n,
   render: h => h(App)
 }).$mount("#app");
 