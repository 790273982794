<template>
  <div class="min-max-bar">
    <div :style="downValue" />
    {{maxF | numeral}}° C
    <div class="barra" :style="heightValue">
    </div>
    {{minF | numeral}}° C
  </div>  
</template>

<script>
// import Vue from 'vue';
// import vueNumeralFilterInstaller from 'vue-numeral-filter';
// Vue.use(vueNumeralFilterInstaller, {locale: 'es'});

export default {
  name: 'forecast-bar',
  components: {

  },
  props: {
    min: {
      type: Number,
      default: 0,
      description: 'Valor global considerado como minimo'
    },
    max: {
      type: Number,
      default: 100,
      description: 'Valor global considerado como maximo'
    },
    minF: {
      type: Number,
      default: 0,
      description: 'Valor pronosticado como minimo'
    },
    maxF: {
      type: Number,
      default: 100,
      description: 'Valor pronosticado como maximo'
    },
  },
  computed: {
    heightValue(){
      let width = (this.maxF - this.minF)/(this.max-this.min);
      return "height: "+width*100+"%";
    },

    downValue(){
      let width = 1-((this.maxF - this.min)/(this.max-this.min));
      return "height: "+width*100+"%"
    }

  },
  data() {
    return {

    }
  },
  methods: {
    
  }

}
</script>

<style>

.min-max-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 15vh;

  font-size: 1.5vh;
  font-weight: bold;
}

.barra {
  width: 30%;
  border: 1px solid #8acfe0;
  background-color: #e2eff4;
  border-radius: 10px;
  margin: 0.5vh 0;
}

@media (max-height: 700px) {
  .min-max-bar {
    height: 10vh;
  }
}

@media (max-height: 800px) {
  .min-max-bar {
    height: 12vh;
  }
}

</style>