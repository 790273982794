<template>
  <div class="wrapper wrapper-full-page wrapper-solcor">
    <div class="main-panel-full">
      <div class="content">
        <div class="row solcor-info">
          <div class="col-4" id="seccion-texto">
            <div class="img-box">
              <img src="/background.jpg" alt="Planta Vitacura">
            </div>
          </div>
          <div class="col-6" id="seccion-info">
            <div class="row info-row text-center">
              <!-- Potencia Actual / Grafico Circulas -->
              <div class="col-6">
                <div class="solcor-card shadow">
                  <div class="solcor-card-title">
                    Potencia Actual
                  </div>
                  <div class="solcor-card-body">
                    <doughnut-chart
                      class="grafico-doughnut"
                      ref="doughnutChart"
                      :chartData="doughnutDataPercentage"
                      :extra-options="doughnutOptions">
                    </doughnut-chart>
                  </div>
                  <div class="solcor-card-footer">
                    <div v-if="isOnline">
                      {{potenciaActual/1000 | numeral('0,0.00')}} kw
                      <br>

                      Utilización {{porcentajeActual | numeral('0,0')}}%
                    </div>
                    <div v-else>
                      Offline
                    </div>
                  </div>
                </div>
              </div>

              <!-- Generacion del dia -->
              <div class="col-6">
                <div class="solcor-card shadow">
                  <div class="solcor-card-title">
                    Balance Energético Hoy
                  </div>

                  <div
                    v-if="newChartData.chartData.datasets[0].data.length == 0"
                    class="solcor-card-body grafico-card" 
                  >
                    No hay datos disponibles
                    <p class="mt-4 mb-5">
                      Última actualización {{ultimaFecha}}
                    </p>
                  </div>

                  <div
                    v-else class="solcor-card-body grafico-card"
                  >
                    <p class="grafico-text">
                      {{ totalGeneratedData }}
                    </p>
                    <line-chart
                      class="grafico-linea"
                      ref="bigChart"
                      chard-id="big-line-chart"
                      :chartData="newChartData.chartData"
                      :extra-options="generationChart.extraOptions"
                    >
                    </line-chart>
                  </div>
                </div>
              </div>
            </div>

            <!-- Clima -->
            <div class="row info-row text-center">
              <div class="col-12">
                <div class="solcor-card shadow">
                  <div class="solcor-card-title">
                    Tiempo
                  </div>
                  <div class="solcor-card-body">
                    <div class="row">
                      <div class="col-5">
                        <div class="solcor-card shadow">
                          <div class="solcor-card-body">
                            <div>
                              <img class="pronostico-icon" :src="icon_route" alt="Pronostico">
                              {{temperature | numeral('0,0')}}° C
                            </div>
                            <div class="div-clima-texto">
                              <span>
                                {{clima_texto}}
                              </span>
                            </div>
                            <div class="viento-div">
                              <img src="/img/icons/wind.svg" alt="Velocidad del viento">
                              {{windSpeed | numeral('0,0.0')}} m/s
                            </div>
                            
                          </div>
                        </div>
                      </div>
                      <div class="col-7">
                        <div class="solcor-card shadow">
                          <div class="solcor-card-body">
                            <div class="div-pronostico">
                              <div v-for="(day, idx) in forecast_array" :key="idx">
                                <div class="mb-3">
                                  <p class="forecast-day">
                                    {{day.Label}}
                                  </p>
                                  <br>
                                  <img class="pronostico-semanal-icon" :src="iconToUrl(day.Image)" alt="Pronostico">
                                </div>
                                <forecast-bar
                                  :minF="day.TempMin.Value"
                                  :maxF="day.TempMax.Value"
                                  :min="minForecast"
                                  :max="maxForecast"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-2 text-center" id="seccion-co2">
            <div class="row">
              <div class="col-12">
                <div class="solcor-card shadow">
                  <div class="solcor-card-title">
                    Ahorro de CO2 Desde el inicio de operación
                  </div>
                  <div class="solcor-card-body">
                    <img class="datos-icon" src="/img/icons/savings_co2emission.svg" alt="CO2">
                    <span class="solcor-card-text">
                      {{co2/1000 | numeral('0,0.00')}}
                    </span>
                    t
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="solcor-card shadow">
                  <div class="solcor-card-title">
                    Equivalencia en distancia recorrida
                  </div>
                  <div class="solcor-card-body">
                    <img class="datos-icon" src="/img/icons/savings_car.svg" alt="CO2 Autos">
                    <span class="solcor-card-text">
                      {{travelCar | numeral}}
                    </span>
                    km
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="solcor-card shadow">
                  <div class="solcor-card-title">
                    Equivalencia de Árboles Plantados
                  </div>
                  <div class="solcor-card-body">
                    <img class="datos-icon" src="/img/icons/savings_trees.svg" alt="CO2 Arboles">
                    <span class="solcor-card-text">
                      {{trees | numeral}}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>  
      </div>

      <footer class="footer text-center">
        <!-- <div class="container-fluid"> -->
          <div class="row">
            <div class="col-3">
              <div class="logo-vitacura">
                <img class="img-logo-vitacura" src="/logo-vitacura.png" alt="Logo Vitacura">
              </div>
            </div>
            <div class="col-6 d-flex justify-content-around">
              <div>
                <img class="footer-icon" src="/img/icons/calendario.svg" alt="Fecha">
                <p class="footer-text">{{currentDate}}</p>
              </div>
              <div>
                <img class="footer-icon" src="/img/icons/reloj.svg" alt="Hora">
                <p class="footer-text">{{currentTime}}</p>
              </div>
              <div>
                <font-awesome-icon icon="smog" class="footer-icon" size="4x"/>
                <p class="footer-text">{{airQty}} ppmv</p>
              </div>
            </div>
            <div class="col-3">
              <div class="logo-solcor">
                <img class="img-logo" src="/solcor-negativo.png" alt="Logo Solcor">
              </div>
              <span class="texto-version">
                V {{ version }}
              </span>
            </div>
          </div>
        <!-- </div> -->
      </footer>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  import _ from 'lodash';
  import config from '@/config';
  import LineChart from '@/components/Charts/LineChart';
  import DoughnutChart from '@/components/Charts/DoughnutChart';
  import ForecastBar from '@/components/ForecastBar';
  import dayjs from 'dayjs';
  var utc = require('dayjs/plugin/utc');
  dayjs.extend(utc);
  var timezone = require('dayjs/plugin/timezone');
  dayjs.extend(timezone)

  require('dayjs/locale/es');
  dayjs.locale('es');
  // dayjs.tz.setDefault("America/Santiago")


  import Vue from 'vue';
  import vueNumeralFilterInstaller from 'vue-numeral-filter';
  Vue.use(vueNumeralFilterInstaller, {locale: 'es'});

  var instance = axios.create();
  delete instance.defaults.headers.common['Authorization'];

  export default {
    name: 'FinalView',
    components: {
      LineChart,
      ForecastBar,
      DoughnutChart
    },
    data(){
      return {
        version: '2.2.1',
        backgroundColor: "primary",
        fnRecargar: null,
        fnActualizar: null,
        lastUpdated: null,
        fnActualizarSegundos: null,
        fnActualizarQty: null,
        fnActualizarForecast: null,
        fnActualizarText: null,
        fnActualizarCO2: null,
        fnActualizarPotencia: null,
        airQty: 0.0,
        currentTime: null,
        currentDate: null,
        generationChart: {
          extraOptions: {
            maintainAspectRatio: false,
            responsive: true,
            legend: {
              display: false
            },
            tooltips: {
              backgroundColor: '#f5f5f5',
              titleFontColor: '#333',
              bodyFontColor: '#666',
              bodySpacing: 4,
              xPadding: 12,
              mode: "nearest",
              intersect: 0,
              position: "nearest"
            },
            scales: {
              yAxes: [{
                barPercentage: 1.6,
                scaleLabel: {
                  display: false,
                  labelString: 'Potencia kW',
                  fontColor: '#fff',
                  fontSize: '18',
                  padding: 8,
                },
                gridLines: {
                  drawBorder: true,
                  // color: 'rgba(29,140,248,0.0)',
                  color: '#000',
                  // zeroLineColor: "transparent",
                  zeroLineColor: '#000',
                },
                ticks: {
                  display: false,
                  fontSize: 15,
                  // suggestedMin: 50,
                  // suggestedMax: 24,
                  padding: 20,
                  // fontColor: "#ff8a76"
                  fontColor: '#000',
                  maxTicksLimit: 4,
                }
              }],
          
              xAxes: [{
                barPercentage: 1.6,
                scaleLabel: {
                  display: false,
                  labelString: 'Hora del día',
                  fontColor: '#000',
                  fontSize: '18',
                  padding: 8,
                },
                gridLines: {
                  drawBorder: true,
                  // color: 'rgba(220,53,69,0.1)',
                  color: '#000',
                  // zeroLineColor: "transparent",
                  zeroLineColor: '#000',
                  borderDash: [8, 4],
                },
                ticks: {
                  padding: 15,
                  fontSize: 12,
                  // fontColor: "#ff8a76",
                  fontColor: '#000',
                  // suggestedMin: 6,
                  // suggestedMax: 6,
                  maxTicksLimit: 5,
                }
              }]
            }
          },
        },
        isOnline: false,
        generationData: [],
        totalGeneratedData: 0,
        temperature: 0.0,
        windSpeed: 0.0,
        co2: 0.0,
        travelCar: 0.0,
        trees: 0,
        peakPower: 100000,
        potenciaActual: null,
        clima_icon: 'clear_sky',
        clima_texto: '',
        forecast_array: [],
        pvSystemID: '4095667a-f799-4843-8dad-71512f133d2f',
        swqapi_base_url: 'https://api.solarweb.com/swqapi/pvsystems/',
        dplus_base_url: 'https://django.deltactivos.cl/api/vitacura',
        qty_url: 'https://sinca.mma.gob.cl/index.php/json/listadomapa2k19/red/macam/',
        channels: [
          'EnergyProductionTotal'
        ],
        textIndex: 0,
        textIndexList: [0, 1],
        doughnutOptions: {
          responsive: true, 
          maintainAspectRatio: false, 
          legend: {
            display: false
          },
          tooltips: {
            enabled: false
          },
          cutoutPercentage: 75
        }, 
      }
    },
    computed: {
      newChartData() {
        let tempData = this.generationData;
        let newLabels = [];
        let newDataset = [];

        tempData.forEach((item) => {
          
          // newLabels.push(item.logDateTime);
          newLabels.push(dayjs(item[0]).format('HH:mm'));
          newDataset.push(item[1]/1000);
        });

        let allData = [];
        allData.push(newDataset);

        return {
          allData,
          chartData: {
            datasets: [{
              fill: true,
              // borderColor: config.colors.primary,
              borderColor: '#c8009c',
              borderWidth: 3,
              borderDash: [],
              borderDashOffset: 0.0,
              // pointBackgroundColor: config.colors.primary,
              pointBackgroundColor: 'blue',
              // pointBorderColor: 'rgba(255,255,255,0)',
              pointBorderColor: 'white',
              pointHoverBackgroundColor: config.colors.primary,
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 0,
              data: newDataset,
            }],
            labels: newLabels,
          }
        };
      },

      icon_route() {
        return `/img/icons/${this.clima_icon}.svg`
      },

      minForecast() {
        return this.forecast_array.reduce((acc, curr) => {
          const temp = curr.TempMin.Value;
          return temp < acc ? temp : acc;
        }, 1000);
      },

      maxForecast() {
        return this.forecast_array.reduce((acc, curr) => {
          const temp = curr.TempMax.Value;
          return temp > acc ? temp : acc;
        }, -1000);
      },

      porcentajeActual() {
        if(this.peakPower == 0 || this.peakPower == null){
          return 0;
        }

        if(this.potenciaActual <= 0 || this.potenciaActual==null){
          return 0;
        }

        return 100*this.potenciaActual/this.peakPower;
      },

      doughnutDataPercentage() {
        if(this.porcentajeActual==0){
          let utilizado = this.porcentajeActual;
          let no_utilizado = 100;
          let no_utilizadoArrayFinal = [...Array(10).keys()].map(x => 10);

          return {
            labels: no_utilizadoArrayFinal.map(x => 'No generado'),
            datasets: [{
              data: no_utilizadoArrayFinal,
              backgroundColor: no_utilizadoArrayFinal.map(x => 'rgb(252, 231, 152)'),
              hoverOffset: 4
            }]
          }
        }

        let utilizado = this.porcentajeActual/10;
        let utilizadoInt = Math.floor(utilizado);
        let utilizadoArray = [...Array(utilizadoInt).keys()].map(x => 10);
        let utilizadoArrayFinal = [...utilizadoArray, (utilizado - utilizadoInt)*10];
        

        let no_utilizado = 10 - utilizado;
        let no_utilizadoInt = Math.floor(no_utilizado);
        let no_utilizadoArray = [...Array(no_utilizadoInt).keys()].map(x => 10);
        let no_utilizadoArrayFinal = [(no_utilizado - no_utilizadoInt)*10, ...no_utilizadoArray];

        let labels = [
          ...utilizadoArrayFinal.map(x => 'Generado'),
          ...no_utilizadoArrayFinal.map(x => 'No Generado'),
        ];

        let backgroundColor = [
          ...utilizadoArrayFinal.map(x => 'rgb(247, 192, 2)'),
          ...no_utilizadoArrayFinal.map(x => 'rgb(252, 231, 152)'),
        ]

        return {
          labels,
          datasets: [{
            data: [...utilizadoArrayFinal, ...no_utilizadoArrayFinal],
            backgroundColor,
            hoverOffset: 4
          }]
        }
      },

      ultimaFecha(){
        if(this.lastUpdated == null){
          return '';
        }
        return this.lastUpdated.format('DD/MM/YYYY HH:mm');
      }

    },
    filters: {
      datetimeToLetter(text, index){
        if(index==0){
          return 'Hoy'
        }

        return dayjs(text).format('dddd')[0].toUpperCase();
      },
    },
    methods: {
      iconToUrl(text){
        return `/img/icons/${text}.svg`;
      },

      updateTextIndex(){
        if(this.textIndex==this.textIndexList.length-1){
          this.textIndex = 0;
        }
        else{
          this.textIndex = this.textIndex + 1;
        }
      },

      updateGenerationData(){
        // Ultima vez a hora actual
        // let from_date = this.lastUpdated;
        // let to_date = dayjs();

        // Inicio del dia a hora actual
        let from_date = dayjs().startOf('day');
        let to_date = dayjs();
        this.getGenerationData(from_date, to_date);
      },

      // Obtiene los valores de calidad del aire
      getQtyData(){
        let url = this.qty_url;

        instance.get(url)
        .then(res => {
          const las_condes_data = res.data.filter((item) => {
            if(item.nombre=="Las Condes"){
              return item;
            }
          });

          let monoxido_value = las_condes_data[0].realtime.filter((item) => {
            if(item.code==='0004'){
              return item;
            }
          });

          this.airQty = monoxido_value[0].tableRow.value;
        })
        .catch(error => {
          console.log(error);
        })
      },


      getPeakData(){
        const url = `${this.dplus_base_url}/potencia`;
        const pvSystemId = this.pvSystemID;

        instance.get(url, {
          params: {
            pvSystemId
          },
        })
        .then(res => {
          this.isOnline = res.data.IsOnline;
          this.potenciaActual = res.data.P_PV;
        })
        .catch(error => {
          console.log(error);
          this.peakPower = null;
        })
      },

      // Obtiene las predicciones de los proximos 7 dias
      getForecastData(){
        const url = `${this.dplus_base_url}/clima`;
        const pvSystemId = this.pvSystemID;

        instance.get(url, {
          params: {
            pvSystemId
          },
        })
        .then(res => {
          const actual = res.data.data.Current;

          this.temperature = actual.Temperature.Value;
          this.windSpeed = actual.WindSpeed.Value;
          this.clima_icon = actual.Image;
          this.clima_texto = actual.TextSummary;

          this.forecast_array = res.data.data.Forecast;

        })
        .catch(error => {
          console.log(error);
        })
      },


      // Obtiene valores de CO2 desde el inicio de la planta
      getCO2Data(){
        const url = `${this.swqapi_base_url}${this.pvSystemID}/aggrdata`

        instance.get(url, {
          params: {
            channel: 'SavingsCO2,SavingsTrees,SavingsTravelCar',
            period: 'total'
          },
          headers: {
            'AccessKeyId': process.env.VUE_APP_SWQAPI_ACCESS_KEY_ID,
            'AccessKeyValue': process.env.VUE_APP_SWQAPI_ACCESS_KEY_VALUE,
          }
        })
        .then(res => {
          let genData = res.data.data[0].channels;
          this.co2 = genData[0].value;
          this.trees = genData[1].value;
          this.travelCar = genData[2].value;
        })
        .catch(error => {
          console.log(error);
        })
      },


      // Obtiene los datos de generacion de la planta
      // desde el inicio del dia
      getGenerationData(from_date, to_date){
        const url = `${this.dplus_base_url}/diario`;
        const pvSystemId = this.pvSystemID;

        instance.get(url, {
          params: {
            pvSystemId
          },
        })
        .then(res => {
          // this.generationData = res.data.data;
          this.generationData = res.data.chart.series[0].data;
          this.totalGeneratedData = res.data.toGrid;
          this.lastUpdated = to_date;
        })
        .catch(error => {
          console.log(error);
        })

      },


      // Actualiza la hora y fecha actual
      getCurrentDateTime(){
        // this.currentTime = dayjs().tz("America/Santiago").format('HH:mm:ss');
        // this.currentDate = dayjs().tz("America/Santiago").format('DD/MM/YYYY')
        // Cambio de hora
        let now =  dayjs().utcOffset(-60*3);
        this.currentTime = now.format('HH:mm:ss');
        this.currentDate = now.format('DD/MM/YYYY')
      },

      recargarPagina(){
        const now =  dayjs().utcOffset(-60*4);
        if(now.hour() === 1){
          console.log('Recargando pagina');
          window.location.reload();
        }
      }
    },

    created(){
      this.fnActualizar = setInterval(this.updateGenerationData, 1000*60*30);
      this.fnActualizarSegundos = setInterval(this.getCurrentDateTime, 1000);
      this.fnActualizarQty = setInterval(this.getQtyData, 1000*60*10);
      this.fnActualizarCO2 = setInterval(this.getCO2Data, 1000*60*60*24);
      this.fnActualizarForecast = setInterval(this.getForecastData, 1000*60*30);
      this.fnActualizarPotencia = setInterval(this.getPeakData, 1000*60*10);
      this.fnRecargar = setInterval(this.recargarPagina, 1000*60*60)
    },

    beforeDestroy(){
      clearInterval(this.fnActualizar);
      clearInterval(this.fnActualizarSegundos);
      clearInterval(this.fnActualizarQty);
      clearInterval(this.fnActualizarCO2);
      clearInterval(this.fnActualizarForecast);
      clearInterval(this.fnActualizarPotencia);
      clearInterval(this.fnRecargar);
    },

    mounted(){
      let now = dayjs();
      this.lastUpdated = now;
      // let start = now.startOf('day').add(6, 'hours');
      let start = now.startOf('day');

      // console.log(start.utc().format('YYYY-MM-DDTHH:mm:ss[Z]'));
      // console.log(now.utc().format('YYYY-MM-DDTHH:mm:ss[Z]'));

      // this.lastDate = now;

      this.getGenerationData(start, now);
      this.getQtyData();
      this.getCO2Data();
      this.getForecastData();
      this.getPeakData();
    },
  };
</script>

<style lang="scss">
$footer-height: 18vh;

$verde-vit: #00c5b6;
$verde2-vit: #bed600;
$violeta-vit: #c8019c;
$height: calc(100vh - #{$footer-height});
$darken-level: 0;

.main-panel-full {
  background: transparent !important;

  &-full {
    > .content {
      min-height: $height;
    }
  }
  
}

.content {
  > .row {
    margin: 0;
  }
}

#seccion-texto {
  min-height: $height;
  max-height: $height;
  background-color: darken(#fff, $darken-level);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
}

.img-box {
  border: 1px solid $verde-vit;
  border-radius: 5px;
  max-width: 100%;
  // max-height: $height;
  height: 100%;

  > img {
    height: 100%;
    object-fit: cover;
    object-position: 46% 50%;
  }
}

.text-main{
  font-size: 2.4rem;
  padding: 2vh 3vh;
  // background-color: rgba($color: #8a8a8acc, $alpha: 0.8);
  background-color: darken(#fff, $darken-level);

  > div > p {
    color: #c8009c;
  }

  >div > ul {
    list-style: none;
    
    padding-top: 2vh;

    > li {
      font-size: 2rem;
    }

    > li:before {
      content: '✓';
      margin-right: 10px;
      color: #bed600;
    }
  }
}


#seccion-info {
  min-height: $height;
  max-height: $height;
  background-color: darken(#fff, $darken-level);
  // color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.info-row{
  color: grey;
  height: 100%;
  // border: solid 1px red;
  padding: 15px;
}

.solcor-card {
  background-color: white;
  padding: 1vh 0;
  height: 100%;
  border-radius: 5px;
  border: 1px solid $verde-vit;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  &-title {
    font-size: 2vh;
    font-weight: bold;
    color: grey;
    border-bottom: solid 1px $verde-vit;
    text-transform: uppercase;
    padding-bottom: 0.5vh;
  }

  &-body {
    font-size: 2.5vh;
    padding: 2vh;
  }

  &-text {
    font-size: 2.5vh;
    color: grey;
  }

  &-footer {
    font-size: 2.5vh;
    border-top: solid 1px $verde-vit;
  }
}

.div-pronostico {
  display: flex;
  justify-content: space-between;
  font-size: 1vh;
}

#seccion-co2 {
  min-height: $height;
  max-height: $height;
  // background-color: rgba(6, 80, 63, 0.8);
  background-color: darken(#fff, $darken-level);
  // color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // padding: 15px 5px 15px 5px
  padding-top: 15px;
  padding-bottom: 15px;
}

.footer {
  background: #fff;
  height: $footer-height;
  
  display: flex;
  flex-direction: column;
  justify-content: center;

  .row {
    margin-right: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.img-logo-vitacura{
  height: 11vh;
}

.img-logo{
  height: 9vh;
}

.datos-icon {
  height: 10vh;
  margin-right: 15px;
}

.pronostico-icon {
  height: 6vh;
  margin-right: 2vh;
}

.inverter-icon {
  height: 10vh;
}

.pronostico-semanal-icon {
  height: 3.5vh;
  // margin-right: 50px;
}

.viento-div {
  margin-top: 5vh;

  > img {
    height: 6vh;
  }
}

.div-clima-texto {
  > span {
    font-size: 3vh;
    text-transform: capitalize;
  }
}

.forecast-day {
  color: grey;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: -10px;
}

.footer-icon {
  height: 7vh;
  color: #3f4544;
}

.footer-text {
  color: black;
  font-weight: 600;
  font-size: 4.5vh
}

.sub-data-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.sub-data {
  margin: 2vh 0;

  > h1 {
    color: white;
    font-weight: 600;
    font-size: 2.5rem;
    margin-bottom: 3px;
  }

  > p {
    color: white;
    font-weight: bold;
    font-size: 1.7rem;
    margin-bottom: 5px;
  }

}

.div-grafico {
  margin-top: 8vh;
}

.grafico-linea {
  width: 100%;
  height: 20.5vh;
}

.grafico-doughnut {
  width: 100%;
  height: 17.5vh;
}

.grafico-card{
  display: flex;
  flex-direction: column;

  > p {
    align-self: baseline;
    color: black;
    margin-bottom: 1vh;
  }
}

.grafico-text {
  color: #000;
  font-size: 2vh;
}

.footer {
  border-top: 2px solid $violeta-vit;
}

.fade-enter-active {
  transition: opacity 1s;
}

.fade-leave-active {
  display: none;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

#doughnut-chart {
  background-image: url('~@/../public/img/icons/inverter.svg');
  background-size: 8vh;
  background-position: center;
  background-repeat: no-repeat;
}

.texto-version {
  position: fixed;
  bottom: 10px;
  right: 10px;
}

@media (max-height: 650px) {

  .inverter-icon {
    height: 8vh;
  }

  .grafico-linea {
    width: 100%;
    height: 20.5vh;
  }
}


@media (max-height: 600px) {
  .pronostico-semanal-icon {
    height: 3vh;
    // margin-right: 50px;
  }
}

@media (max-height: 700px) {
  .viento-div {
    margin-top: 3vh;

    > img {
      height: 4vh;
    }
  }
}

@media (max-height: 850px) {

  .div-grafico {
    margin-top: 8vh;
  }
}
</style>